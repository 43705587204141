import React, { useState, useEffect } from "react";
import Image from "next/future/image";
// Redux
import { useSelector, useDispatch } from "react-redux";
import tokenThunkAPI from "store/features/token/middleware";
//IMPORT IMAGES
import sweplyLogo from "static/images/sweplyLogo.svg";
import viewBtnArrow from "static/images/viewbtnarrow.svg";
import viewBtnArrowBlack from "static/images/viewbtnarrowblack.svg";
import logoMetaMask from "static/images/logo-metamask.svg";
import logoTrustWallet from "static/images/logo-trustwallet.svg";
import Link from "next/link";

//IMPORT SWIPER
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
//IMPORT COMPONENT
import EndSales from "./EndSales";
import { commarize, zeroCount } from "utils/helpers";
import copy from "copy-to-clipboard";
import ProgressBar from "componants/common/ProgressBar";
import { successAlert } from "../../../utils/sweetAlert";
import Status from "componants/common/Status";
import { addTokenToMetamask, addToTrust } from "../../../utils";
export default function Cart(props) {
  const dispatch = useDispatch();
  const [tokenDetails, setTokenDetails] = useState({
    marketCap: 0,
    liquidity: 0,
    marketValue: 0,
  });
  const {
    index,
    status,
    isKYC,
    isAudit,
    isSAYF,
    tokenName,
    token_symbol,
    contractAddress,
    created_date,
    start_date,
    end_date,
    currency,
    Id,
    isLiked,
    isLike,
    fund_release_percent,
    handleLikeUnlike,
    detailPageLink,
    imageurl,
    progresspercentage,
    softcap,
    hardcap,
    title,
    filterLabel,
    get_total_invested_amount,
    contract_status,
    parantClassName,
    forFilter,
    onChangeTokenCartNetwork,
    token_data,
    selected_token_data,
    liquidity_value,
    token_value,
    market_value,
  } = props;
  const [ShowDateStatus, setShowDateStatus] = useState("");
  const [tokenPriceSub, setTokenPriceSub] = useState(0.0);
  const [tokenPriceEnd, setTokenPriceEnd] = useState(0.0);
  const [tokenValueCount, setTokenValueCount] = useState(0);
  //copy functionality
  const [copiedText, setCopiedText] = useState("Copy");

  const copyTextChange = () => {
    setCopiedText("Copy");
  };

  const handaleCopy = async (textToCopy) => {
    setCopiedText("Copied");
    setTimeout(copyTextChange, 1000);

    copy(textToCopy);
  };
  // get token data
  const {
    singleTokenDetails,
    singleStatus,
    tokenPoolAddress,
    poolDetails,
    tokenPageFavouriteStatus,
  } = useSelector((state) => state.tokenState);

  useEffect(() => {
    setTokenDetails({
      marketCap: parseFloat(
        poolDetails?.[0].attributes?.fully_diluted_valuation
      ).toFixed(3),
      liquidity: parseFloat(
        poolDetails?.[0].attributes?.reserve_in_usd
      ).toFixed(3),
      marketValue: parseFloat(
        poolDetails?.[0].attributes?.price_in_usd
      ).toFixed(10),
    });
  }, [poolDetails]);

  useEffect(() => {
    if (token_value) {
      let count = zeroCount(token_value)
      setTokenValueCount(count)
      let x = Number(token_value)
      let m = -Math.floor(Math.log10(x) + 1);
      let y = token_value.toString().split('.')[1]
      if (count > 2) {
        setTokenPriceSub(m)
        y.slice(m)
        let w = y.slice(m)
        setTokenPriceEnd(w)
      } else {
        setTokenPriceEnd(y)
      }
    }
  }, [token_value]);

  return (
    <div className={parantClassName ? parantClassName : ""}>
      <div className="latestLaunchpadBox createTokenPageCartMain">
        <div className="latestLaunchpadHead">
          <div className="cardImgNameInfo">
            <div className="createTokenPageCardImg">
              <Image
                src={imageurl ? imageurl : sweplyLogo}
                alt="image"
                width={40}
                height={40}
                style={{ borderRadius: "50%" }}
              />
            </div>
            <div className="createTokenPageCardName">
              <h3 className="font18 fontBold">{tokenName}</h3>
              {token_symbol && <p>({token_symbol})</p>}
            </div>
          </div>

          <div className="latestLaunchpadDetails">
            <div className={(isLiked && "likeBox active") || "likeBox"}>
              <i
                className="far fa-heart"
                onClick={() => handleLikeUnlike(Id, isLiked)}
              ></i>
            </div>

            <div className="clearfix"></div>

            {isKYC && <span className="liveBox kyc font12">KYC</span>}
          </div>
        </div>

        {title == "token-explorer" && (
          <>
            <div className="word-wrap mb16">
              <div className="contCodeShareBox mb16">
                <span className="contTitle">Contract</span>
                <span className="contCodeDetails colorGrey">
                  {`${token_data[0]?.contract_address.substring(
                    0,
                    6
                  )}...${token_data[0]?.contract_address.slice(-5)}`}
                  <span className='posRelative customTooltipHover cursor-pointer' onClick={() => handaleCopy(token_data[0]?.contract_address)}>
                    <i className='far fa-copy'></i>
                    <span className='posAbsolute customTooltip'>
                      <span className='tooltipCopyTxtword'>
                        {copiedText || "-"}
                      </span>
                    </span>
                  </span>
                  {/* <span
                    onClick={() => handaleCopy(token_data[0]?.contract_address)}
                    className="cursor-pointer"
                  >
                    <i className="far fa-copy"></i>
                  </span> */}
                </span>
                <span className="metaTrustBox">
                  <span
                    className="posRelative customTooltipHover"
                    onClick={() => addTokenToMetamask(token_data)}
                  >
                    <Image alt="" className="curPointer" src={logoMetaMask} />
                    <span className="posAbsolute customTooltip">
                      <span className="tooltipCopyTxtword">
                        Add to MetaMask
                      </span>
                    </span>
                  </span>
                  {/* <span
                    className="posRelative customTooltipHover"
                    onClick={() => addToTrust(token_data)}
                  >
                    <Image
                      alt=""
                      className="curPointer"
                      src={logoTrustWallet}
                    />
                    <span className="posAbsolute customTooltip">
                      <span className="tooltipCopyTxtword">
                        Add to Trust Wallet
                      </span>
                    </span>
                  </span> */}
                </span>
              </div>

              <div className="networkBtnSection">
                <div className="networkBtnSectionHead mb8">Network</div>
                <div className="networkBtnSectionBtns">
                  <div className="radio-btns">
                    {token_data.map((item, token_d_index) => (
                      <div className="radio-btn" key={token_d_index}>
                        <input
                          type="radio"
                          id={Id + item?.networkName + ""}
                          name={Id + "name"}
                          checked={
                            item?.networkName ==
                            selected_token_data?.networkName
                          }
                          onClick={() =>
                            onChangeTokenCartNetwork(
                              item,
                              token_d_index,
                              Id,
                              index
                            )
                          }
                        />
                        <label htmlFor={Id + item?.networkName + ""}>
                          {item?.networkName}
                        </label>
                      </div>
                    ))}

                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {hardcap && (
          <p className="font14">
            {get_total_invested_amount || "0.0"}/{hardcap} {currency}
          </p>
        )}

        {fund_release_percent && (
          <p className="font14">
            Project will receive {fund_release_percent}% at first release
          </p>
        )}

        <div className="createTokenPagecardAmount mb16">
          <div className="createTokenPageAmount">
            {token_value ?
              <div className="fullAndShortBox">
                <div className="shortName cursor-pointer">{Math.floor(token_value)}.{tokenValueCount > 2 ? '0' : null}{tokenValueCount > 2 ? <sub>{tokenPriceSub}</sub> : null}{tokenPriceEnd.toString().slice(0, 4)}</div>
                <div className="FullName cursor-pointer">{token_value.toString().length > 20 ? Number(token_value).toFixed(20) : token_value}</div>
              </div> : "--"}
          </div>
        </div>

        <div className="d-flex">
          <div className="marketCapLiquiditySection">
            <div className="marketCapLiquidityHead">Market cap</div>
            <div className="marketCapLiquidityPrice">
              {market_value ? `$${commarize(Number(market_value))}` : "--"}
            </div>
          </div>
          <div className="marketCapLiquiditySection">
            <div className="marketCapLiquidityHead">Liquidity</div>
            <div className="marketCapLiquidityPrice">
              {liquidity_value
                ? `$${commarize(Number(liquidity_value))}`
                : "--"}
            </div>
          </div>
        </div>

        <div className="createTokenPageCardBottom d-flex align-items-center justify-content-between">
          <div className="tokenPageCardKYCBtns"></div>

          {title == "token-explorer" && (
            <div className="saleEndTimerBox">
              <p className="font16"></p>
              {token_value ? (
                <Link className="" href={props?.tokenpageurl}>
                  <span className="launchpadViewBtn cursor-pointer">
                    View
                    <Image alt="" src={viewBtnArrow} className="viewBtnArrow" />
                    <Image
                      alt=""
                      src={viewBtnArrowBlack}
                      className="viewBtnArrowBlack"
                    />
                  </span>
                </Link>
              ) : (
                <span className="launchpadViewBtn cursor-pointer">
                  Not Listed
                  {/* <Image alt="" src={viewBtnArrow} className="viewBtnArrow" />
                    <Image
                      alt=""
                      src={viewBtnArrowBlack}
                      className="viewBtnArrowBlack"
                    /> */}
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
